import styled, { css } from 'styled-components'
import { BreakPoint } from '../variables/'

export const Component = styled.div`
	${props => props.bgcolour ? css`
		background: ${props.bgcolour.primary};
		color: ${props.bgcolour.secondary};
		padding: 30px;
		@media ${BreakPoint.md} {
			padding: 100px;
		}
	`: css`
		margin: 30px;
		@media ${BreakPoint.md} {
			margin: 100px;
		}
	`}
	
`