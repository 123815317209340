import React from 'react'
import Snowfall from 'react-snowfall'

export const SnowFall = () => {

	return (
		<div className="absolute w-full h-full top-0 left-0 overflow-hidden pointer-events-none">
			<Snowfall color="white" />
		</div>
	)
}
