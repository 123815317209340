import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

import { Header } from "./";
import { BgImage } from "../styles/";

export const Page = ({ headerAction, children, bgImage, hideHeader }) => {

	return (

		<div className="absolute w-full h-full flex flex-col">
			
			<main className={'relative flex-1 flex flex-col items-center justify-center'}>
				{bgImage &&
					<BgImage>
						<StaticImage src="../../images/general/homepage-bg.jpg" placeholder="blurred" alt="" />
					</BgImage>
				}
				{ children }
			</main>
			<Header action={headerAction} hideHeader={hideHeader}/>
		</div>

	)
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}
