import React  from "react"
import styled from 'styled-components'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
	width: 100%;
	position: relative;
	aspect-ratio: ${props => props.ratio ? props.ratio : 'auto'};
	border-radius: 10px;
	overflow: hidden;
	background: #eee;
	pointer-events: none;
`
const Image = styled.div`
	width: 100%;
	height: 100%;
	>div {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit:cover;
		overflow: hidden;
	}
	img {
		bottom: 0;
		height: 100%;
		left: 0;
		margin: 0;
		max-width: none;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		object-fit: cover;
	}
`

export const ImageBox = ({ alt, imageData, placeholder, remote, ratio }) => {

	const thisImage = getImage(imageData)
	const thisPlaceholder = getImage(placeholder)

	return (

		<Wrapper ratio={ ratio }>
			{thisImage &&
				<Image>
					<GatsbyImage image={ thisImage ?? thisPlaceholder } alt={ alt ?? 'image' } />		
				</Image>
			}
			
		</Wrapper>
	)
}
