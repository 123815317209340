import React from "react"
import { Link } from "gatsby"

import LogoImage from "../../images/logos/logo-white.svg"

export const Logo = ({theme}) => {

	return (
		<a className="flex cursor-pointer" href="https://satellitecreative.com/" target="_blank">
			<LogoImage className="block w-[120px] h-[25px]"/>
		</a>
	)
}
