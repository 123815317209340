import React, { Children } from "react"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/scss';
import 'swiper/scss/pagination';

export const Slider = ({children, swiperClass, slideClass, slidesPerView, spaceBetween}) => {

	return (
		children &&
		<Swiper
			className={swiperClass}
			spaceBetween={spaceBetween ?? 0}
			slidesPerView={slidesPerView ?? 1}
			slidesPerGroup={3}
			modules={[Pagination]}
			pagination={{ clickable: true }}>
			{Children.map(children, (child, i) => (
				<SwiperSlide key={'slide-'+i} className={slideClass}>
					{child}
				</SwiperSlide>
			))}
		</Swiper>
	)
}