import styled from 'styled-components'

export const TextContent = styled.div`
	color: ${props => props.colour ? props.colour : "inherit"};
	text-align: ${props => props.center ? "center" : "inherit"};
	> *, > div > * {
		margin-bottom: 0;
		&:last-child {
			margin-bottom: 0!important;
		}
	}
	h1, h2, h3, h4, h5, h6, p {
		margin-bottom: 20px;
	}
`
