import styled from 'styled-components'

export const BgImage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	>div {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit:cover;
		overflow: hidden;
	}
	img {
		bottom: 0;
		height: 100%;
		left: 0;
		margin: 0;
		max-width: none;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		object-fit: cover;
	}
`
