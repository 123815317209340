import React from "react"
import styled, { css } from 'styled-components'

import { Field } from "../../styles/"
import { FontSize, FactoryYellow, Purple, GreyDark } from "../../variables"

const ToggleWrapper = styled.div`
	display: flex;
	align-items: center;
	${FontSize.xsm}	
	&:before {
		content: "${props => props.leftText}";
	}
	&:after {
		content: "${props => props.rightText}";
	}
`
const ToggleSlider = styled.div`
	height: 50px;
	width: 100px;
	border-radius: 25px;
	margin: 0 10px;
	background: ${GreyDark.primary};
	position: relative;
	cursor: pointer;
	transition: all 0.3s ease;
	${props => props.state && css`
		background: ${GreyDark.primary};
	`}
`
const Switch = styled.div`
	height: 44px;
	width: 44px;
	border-radius: 50%;
	background: ${FactoryYellow.primary};
	position: absolute;
	top: 3px;
	left: 3px;
	z-index: 1;
	transition: all 0.3s ease;
	${props => props.state && css`
		background: ${Purple.primary};
		transform: translate(50px, 0);
	`}
`
export const ToggleField = ({ onClick, state, leftText, rightText, label }) => {

	return (
		<Field>
			{label &&
				<label>
					<p>{label}</p>
				</label>
			}
			<ToggleWrapper leftText={leftText} rightText={rightText}>
				<ToggleSlider state={state} role="button" tabIndex={0} onClick={onClick} onKeyDown={onClick}>
					<Switch state={state}/>
				</ToggleSlider>
			</ToggleWrapper>
		</Field>
	)
}
