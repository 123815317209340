import React, { Children } from "react"

export const Modal = ({bgColour, children, open, openCallback}) => {

	return (
		open && children &&
		<div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center">
			<span role="button" tabIndex={0} onClick={() => openCallback()} onKeyDown={() => openCallback()} className="absolute top-0 left-0 w-full h-full bg-white opacity-80"></span>
			<div className={(bgColour ? bgColour : 'bg-white text-black ') + "relative overflow-y-auto max-h-full block px-5 py-6 md:p-10 rounded w-full max-w-[600px] drop-shadow-lg"}>
				{Children.map(children, (child, i) => (
					<>{child}</>
				))}
			</div>
		</div>
	)
}