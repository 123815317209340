//Black
export const Black = {
	primary: "#080808",
	secondary: "#fff" 
}
//White
export const White = {
	primary: "#ffffff",
	secondary: "#080808" 
}
//Grey
export const Grey = {
	primary: "#eee",
	secondary: "#080808"
}
export const GreyLight = {
	primary: "#f5f2ea",
	secondary: "#080808"
}
export const GreyDark = {
	primary: "#596673",
	secondary: "#ffffff",
}

export const FactoryYellow = {
	primary: "#FEDD00",
	secondary: "#080808"
}
export const Green = {
	primary: "#97D700",
	secondary: "#080808"
}
export const BrightRed = {
	primary: "#EF3340",
	secondary: "#080808"
}
export const Turquoise = {
	primary: "#2DCCD3",
	secondary: "#080808"
}