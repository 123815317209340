import React, { useRef, useState } from "react"
import { Player } from '@lottiefiles/react-lottie-player';

export const Lottie = ({ src, style, loop, autoplay, minutes }) => {

    const [animationInstance, setAnimationInstance] = useState(null)
    const lottieRef = useRef(null);

    function handleEventPlayer(e) {
        if (e === 'frame') {
            if(animationInstance.currentFrame >= minutes) {
                lottieRef.current.pause();
            }
        }
	}

	return (
		src && 
            <Player
                lottieRef={instance => {
                    setAnimationInstance(instance) 
                }}
                ref={lottieRef}
                autoplay={autoplay}
                loop={loop}
                keepLastFrame={true}
                src={src}
                style={style}
                onEvent={handleEventPlayer}>
            </Player>
	)
}