import React, { useRef } from "react"
import styled from 'styled-components'

import { Field } from "../../styles/"
import { Black, TickIcon } from "../../variables"

const Wrapper = styled.label`
	display: flex;
	align-items: center;
	margin-bottom: 10px!important;
	&:last-child {
		margin-bottom: 0px!important;
	}
`
const Radio = styled.div`
	position: relative;
	width: 2rem;
	height: 2rem;
	input {
		position: absolute;
		width: auto;
		z-index: -1;
		opacity: 0;
		&:checked {
			~ div {
				svg {
					opacity: 1;
				}
			}
		}
	}
	div {
		position: absolute;
		overflow: hidden;
		top: 0;
		left: 0;
		height: 2rem;
		width: 2rem;
		border: 2px solid ${Black.primary};
		border-radius: 2px;
		pointer-events: none;
		color: ${Black.primary};
		svg {
			opacity: 0;
			position: absolute;
			overflow: hidden;
			top: 0.3rem;
			left: 0.2rem;
			font-size: 1.2rem;
		}
	}
`
const Text = styled.span`
	margin-left: 10px!important;
	flex: 1;
`

export const CheckField = ({ label, name, onChange, required, value, setValue }) => {

	const inputRef = useRef();

	const handleChange = (evt) => {
		evt.persist()
		setValue(currentValues => ({
			...currentValues,
			[name]: inputRef.current.checked ? true : false
		}))
	}

	return (
		<Wrapper>
			<Radio>
				<input 
					type="checkbox"
					name={ name }
					value={ value }
					ref={inputRef}
					onChange={ onChange ?? handleChange }
					required={ required ? true : false }/>
				<div>
					{value === true && <TickIcon/>}
				</div>
			</Radio>
			<Text className="text-xs">{ label }</Text>
		</Wrapper>
	)
}
