import { Link } from "gatsby"
import styled, { css } from 'styled-components'
import { Black } from '../variables/'

export const ButtonStyles = css`
	cursor: pointer;
	${props => props.simple ? css`
		position: relative;
		background: transparent;
		text-decoration: underline;
		text-underline-offset: 4px;
		&:hover {
			text-underline-offset: 2px;
		}
	`: css`
		letter-spacing: 0.15em;
		text-decoration: none;
		width: ${props => props.full ? '100%' : 'auto'};
		min-width: 130px;
		display: inline-block;
		cursor: pointer;
		text-align: center;
		padding: 5px 10px;
		border: 2px solid transparent;
		border-radius: 10px;
		background: ${props => props.bgcolour ? props.bgcolour.primary : Black.primary};
		color: ${props => props.bgcolour ? props.bgcolour.secondary : Black.secondary};
		text-transform: uppercase;
		&:hover {
			background: transparent;
			border-color: ${props => props.bgcolour ? props.bgcolour.primary : Black.primary};
			color: ${props => props.bgcolour ? props.bgcolour.primary : Black.primary};
		}
	`
	}
	${props => props.disable && css`
		opacity: 0.5;
		pointer-events: none;
	`
	}
`
export const ButtonLink = styled(Link)`
	${ButtonStyles}
`
export const ButtonExternal = styled.a`
	${ButtonStyles}
`
export const Button = styled.button`
	${ButtonStyles}
`
