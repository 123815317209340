import React from "react"

import { Logo } from "../partials/";
import { ButtonLink } from "../styles/";
import { Green } from "../variables/";

export const Header = ({ action, hideHeader }) => {

	return (
		!hideHeader &&
			<header className="sticky z-10 bottom-0 w-full bg-black text-white">
				<div className="flex items-center justify-between p-4">
					<Logo/>
					{action &&
						action === 'create' ?
							<ButtonLink bgcolour={Green} to='/builder'>Create card</ButtonLink>
						: 
						action === 'find' ?
							<ButtonLink bgcolour={Green} to='/card'>Find card</ButtonLink>
						: 
						null
					}
				</div> 
			</header>
		
	)
}