import styled from 'styled-components'

export const Field = styled.div`
	position: relative;
	width: ${props => props.short ? "auto" : "100%"};
	margin-bottom: 10px;
	&:last-child {
		margin-bottom: 0;
	}
	p {
		margin-bottom: 10px;
	}
	label {
		font-weight: bold;
	}
	textarea {
		resize:none; 
	}
	input, select, textarea {
		cursor: pointer;
		text-align: ${props => props.align ? props.align : "left"};
		width: ${props => props.short ? "auto" : "100%"};
		min-height: 40px;
		display: block;
		outline: none;
		border: none;
		padding: 10px 0;
		// border-bottom: 1px solid;
		border-radius: 2px;
		border-color: black;
		background: ${props => props.bgColour ? props.bgColour.primary : "transparent"};
		color: ${props => props.bgColour ? props.bgColour.secondary : "inherit"};
		font-family: inherit;
		&::placeholder {
			color: ${props => props.bgColour ? props.bgColour.primary : "inherit"};
			opacity: 0.4;
		}
		  
		&::-ms-input-placeholder { /* Edge 12 -18 */
			color: ${props => props.bgColour ? props.bgColour.primary : "inherit"};
		}
		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus {
		  -webkit-text-fill-color: inherit;
		  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
		  transition: background-color 5000s ease-in-out 0s;
	}
`
