import React from "react"
import PropTypes from "prop-types"

//redux
import { Provider } from 'react-redux'
import store from '../store/configureStore'

//google recptcha
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const  RootLayout = ({ children }) => {

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
      container={{
        parameters: {
          badge: 'inline',
        }
      }}>
      <Provider store={store}>
          { children }
      </Provider>
    </GoogleReCaptchaProvider>
	)
}

RootLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
