import React, { useRef } from "react"

import { Field } from "../../styles/"

export const TextareaField = ({ label, maxLength, name, onChange, placeholder, required, rows, value, setValue }) => {

	const inputRef = useRef();

	function handleChange(e) {
		e.persist();
		setValue(currentValues => ({
			...currentValues,
			[name]: inputRef.current.value
		}))
	}

	return (
		<Field>
			<label>
				{label &&
					<p>{label}{required && '*'}</p>
				}
				<textarea
					name={ name }
					placeholder={ placeholder }
					value={ value }
					ref={inputRef}
					onChange={ onChange ?? handleChange }
					required={ required ? true : false }
					maxLength={ maxLength }
					rows={ rows ?? '4' }/>
					{ maxLength &&
						<p className="text-xs opacity-50">Max character length: { maxLength }</p>
					}
			</label>
		</Field>
	)
}
